import React, { useState, useEffect, useRef } from 'react';
import SimplePeer from 'simple-peer';

const WebRTCConnection = () => {
  const [clientId, setClientId] = useState(null);
  const [remoteId, setRemoteId] = useState('');
  const [peer, setPeer] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [audioLevel, setAudioLevel] = useState(0);
  const signalingServer = useRef(null);
  const localStream = useRef(null);
  const remoteAudio = useRef(null);
  const audioContext = useRef(null);
  const analyser = useRef(null);
  const dataArray = useRef(null);

  useEffect(() => {
    // Fetch available audio devices and connect to signaling server
    async function fetchMediaDevices() {
      try {
        // Request audio permissions
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

        // Get available audio input devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioDevices = devices.filter((device) => device.kind === 'audioinput');
        setDevices(audioDevices);
        if (audioDevices.length > 0) {
          setSelectedDevice(audioDevices[0].deviceId);
        }
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    }

    // Connect to the signaling server
    signalingServer.current = new WebSocket('wss://egunar.com/ws/'); // Ensure correct URL
    signalingServer.current.onmessage = (message) => {
      const data = JSON.parse(message.data);

      switch (data.type) {
        case 'id':
          setClientId(data.id);
          break;
        case 'offer':
          handleOffer(data);
          break;
        case 'answer':
          handleAnswer(data);
          break;
        case 'candidate':
          handleCandidate(data);
          break;
        default:
          break;
      }
    };

    fetchMediaDevices();

    // Cleanup on unmount
    return () => {
      if (signalingServer.current) signalingServer.current.close();
    };
  }, []);

  const startConnection = async () => {
    if (!selectedDevice) {
      alert('Please select an audio input device.');
      return;
    }

    // Get local media stream from the selected audio input device
    try {
      localStream.current = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: selectedDevice } }
      });

      // Set up Web Audio API for visualizing audio input
      setupAudioContext(localStream.current);
      console.log('STREAM ', localStream.current);
      // Create a new peer with initiator set to true
      const newPeer = new SimplePeer({
        initiator: true,
        trickle: false,
        stream: localStream.current,
      });

      setPeer(newPeer);

      // Handle peer signaling data
      newPeer.on('signal', (data) => {
        signalingServer.current.send(
          JSON.stringify({ type: 'offer', offer: data, target: remoteId, sender: clientId })
        );
      });

      newPeer.on('close', () => {
        console.log('Peer connection closed');
      });

    } catch (error) {
      console.error('Error accessing local media:', error);
    }
  };

  const setupAudioContext = (stream) => {
    // Create AudioContext and AnalyserNode for volume visualization
    audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
    analyser.current = audioContext.current.createAnalyser();
    analyser.current.fftSize = 256;
    const source = audioContext.current.createMediaStreamSource(stream);
    source.connect(analyser.current);

    // Create a data array to hold audio data
    dataArray.current = new Uint8Array(analyser.current.frequencyBinCount);

    // Start monitoring audio levels
    monitorAudioLevel();
  };

  const monitorAudioLevel = () => {
    requestAnimationFrame(monitorAudioLevel);
    analyser.current.getByteFrequencyData(dataArray.current);

    // Calculate the average volume level
    let sum = 0;
    for (let i = 0; i < dataArray.current.length; i++) {
      sum += dataArray.current[i];
    }
    const average = sum / dataArray.current.length;
    setAudioLevel(average);
  };

  const handleOffer = (data) => {
    const newPeer = new SimplePeer({
      initiator: false,
      trickle: false,
      // stream: localStream.current,
    });

    setPeer(newPeer);

    newPeer.on('signal', (answer) => {
      signalingServer.current.send(
        JSON.stringify({ type: 'answer', answer: answer, target: data.sender, sender: clientId })
      );
    });

    newPeer.signal(data.offer);

    // Handle incoming remote stream
    newPeer.on('stream', (stream) => {
      console.log('Received remote stream ', stream.id);
      try {

        remoteAudio.current.srcObject = stream;
        remoteAudio.current.play();
      } catch (ex) {
        console.warn('ERROR recieving stream ', ex);
      }
    });

    newPeer.on('error', (err) => {
      console.error('Peer connection error:', err);
    });

    newPeer.on('close', () => {
      console.log('Peer connection closed');
    });
  };

  const handleAnswer = (data) => {
    if (peer) {
      peer.signal(data.answer);
    }
  };

  const handleCandidate = (data) => {
    if (peer) {
      peer.signal(data.candidate);
    }
  };

  return (
    <div>
      <h1>Online Jam</h1>
      <p>Your ID: {clientId}</p>
      <label htmlFor="audioSource">Select Audio Input: </label>
      <select
        id="audioSource"
        value={selectedDevice}
        onChange={(e) => setSelectedDevice(e.target.value)}
      >
        {devices.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label || `Device ${device.deviceId}`}
          </option>
        ))}
      </select>
      <br />
      <input
        type="text"
        placeholder="Enter remote ID"
        value={remoteId}
        onChange={(e) => setRemoteId(e.target.value)}
      />
      <button onClick={startConnection}>Start Jamming</button>
      <div
        className="audio-level-meter"
        style={{ width: `${audioLevel}%`, height: '10px', background: 'green', marginTop: '10px' }}
      />
      {/* <h2>LOCAL STREAM</h2>
      <video ref={localStream} controls autoPlay /> */}
      <h2>REMOTE STREAM</h2>
      <audio ref={remoteAudio} controls autoPlay />
    </div>
  );
};

export default WebRTCConnection;
